body {
    /* font-family: 'Segoe UI', Montserrat, Arial; */
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Segoe UI', Arial, 'Montserrat'; */
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sitename {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* .sitename>.big {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
} */

/* .sitename>.small {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .textbutton {
        @apply bg-primary2 text-onPrimary hover:text-onSecondary/50 disabled:text-onSecondary/50 drop-shadow rounded-lg px-2 py-1 border-0 select-none
    }

    .redbutton {
        @apply bg-error text-onError hover:text-onError/75 disabled:text-onError/75 drop-shadow rounded-lg px-2 py-1 border-0 select-none
    }

    .greenbutton {
        @apply bg-success disabled:bg-disabled text-onSuccess hover:text-onError/75 disabled:text-onError/75 drop-shadow rounded-lg px-2 py-1 border-0 select-none
    }

    .iconbutton {
        @apply flex justify-center items-center w-[30px] h-[30px] bg-primary2 text-onPrimary hover:text-onSecondary/50 disabled:text-onSecondary/50 drop-shadow rounded-full border-0 select-none
    }

    .iconbutton.transparent {
        @apply bg-primary/25 text-onPrimary hover:text-onPrimary hover:bg-primary/75 disabled:text-onSecondary/50 drop-shadow-none
    }

    .iconbutton.big {
        @apply w-[40px] h-[40px]
    }

    .linkbutton {
        @apply hover:text-onPrimary/75 hover:underline disabled:text-onPrimary/75 disabled:no-underline underline-offset-1
    }

    .navbutton {
        /* @apply hidden md:visible */
    }

    .appcontainer {}

    .maincontainer {
        @apply mx-auto px-4 md:w-[768px] pt-big
        /* container */
    }

    .mainpage {
        @apply w-full md:w-[500px]
    }

    .textfield {
        @apply text-base bg-primary2 px-2 py-1 rounded font-light
    }

    .debugfield {
        @apply text-disabled text-sm
    }

    .textinput {
        @apply w-full bg-white/5 placeholder:italic placeholder:text-onPrimary/75 border-0 px-2 py-2 rounded-md focus:outline-none focus:ring-secondary focus:ring-1 disabled:text-onPrimary/50
    }

    .combobox {
        @apply w-full bg-white/5 border-0 px-2 py-2 rounded-md focus:outline-none focus:ring-secondary focus:ring-1 disabled:text-onPrimary/50
    }

    .combobox option {
        @apply bg-primary text-onPrimary px-2 py-2 my-2
    }

    .checkbox {
        @apply mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-secondary disabled:border-disabled outline-none checked:border-secondary checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-onPrimary hover:cursor-pointer focus:shadow-none
    }
    /* .checkbox:disabled {
        @apply text
    } */

    /* .checkbox.left {

    }
    .checkbox.right {
        @apply -ml-[1.5rem] 
    } */
    .items {
        @apply flex flex-col divide-y divide-onPrimary/25
    }

    .itemrow {
        @apply cursor-default hover:text-onPrimary/[.9] px-4 py-2 select-none hover:bg-white/5
    }

    .itemrow.disabled {
        @apply text-onPrimary/[.9] hover:text-onPrimary/[.9] hover:bg-primary
    }

    .menuitem {
        @apply cursor-default hover:text-onPrimary/[.9] px-2 py-1 select-none hover:bg-white/5
    }

    .menuitem.disabled {
        @apply text-onPrimary/[.9] hover:text-onPrimary/[.9] hover:bg-primary
    }

    .header {
        @apply text-6xl self-end md:text-8xl
    }

    .description>a {
        /* color: blue; */
        text-decoration-line: underline;
    }

    .description>a:hover {
        color: white;
    }

    .absolute-center {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    }

    .absolute-x-center {
        @apply absolute left-1/2 transform -translate-x-1/2
    }

    .absolute-y-center {
        @apply absolute top-1/2 transform -translate-y-1/2
    }

    .loading {
        width: 30px;
        height: 30px;
        border: 3px solid transparent;
        border-top: 3px solid;
        border-bottom: 3px solid;
        /* border-right: 3px solid; */
        border-radius: 100%;
        animation: animateC 1s linear infinite;
    }

    .progress-bar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        /* background: 
          radial-gradient(closest-side, white 79%, transparent 80% 100%),
          conic-gradient(hotpink 15%, pink 0); */
    }

    .loading-progress {
        /* width: 30px; */
        height: 10px;
        /* background-color: #fff000; */
        /* border: 3px solid transparent; */
        /* border-top: 3px solid; */
        /* border-bottom: 3px solid; */
        /* border-right: 3px solid; */
        /* border-radius: 100%; */
        /* animation: animateC 1s linear infinite; */
    }

    /* .loading > span {
        width:100%;
        height:100%;
        border: 3px solid #fff000;
        border-radius:50%;
    } */

    .loading-ring {
        /* position:absolute; */
        /* top:50%; */
        /* left:50%; */
        /* transform: translate(-50%,-50%); */
        width: 150px;
        height: 150px;
        line-height: 150px;
        background: transparent;
        border: 3px solid #3c3c3c;
        border-radius: 50%;
        text-align: center;
        font-family: sans-serif;
        font-size: 20px;
        /* color:#fff000; */
        /* letter-spacing:4px; */
        /* text-transform:uppercase; */
        /* text-shadow:0 0 10px #fff000; */
        /* box-shadow:0 0 20px rgba(0,0,0,.5); */
    }

    .loading-ring:before {
        content: '';
        /* position:absolute; */
        /* top:-0px; */
        /* left:-0px; */
        width: 100%;
        height: 100%;
        border: 3px solid transparent;
        border-top: 3px solid #fff000;
        border-right: 3px solid #fff000;
        border-radius: 50%;
        animation: animateC 2s linear infinite;
    }

    .loading-ring>span {
        display: block;
        /* position:absolute; */
        /* top:calc(50% - 2px); */
        left: 50%;
        width: 50%;
        height: 4px;
        background: transparent;
        transform-origin: left;
        animation: animate 2s linear infinite;
    }

    .loading-ring>span:before {
        content: '';
        /* position:absolute; */
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #fff000;
        top: -6px;
        right: -8px;
        box-shadow: 0 0 20px #fff000;
    }

    @keyframes animateC {
        0% {
            transform: rotate(0deg);
        }

        /* 50% {
            transform:rotate(100deg);
        } */
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes animate {
        0% {
            transform: rotate(45deg);
        }

        100% {
            transform: rotate(405deg);
        }
    }

    .slidewrapper {
        @apply w-screen lg:w-full overflow-x-auto mx-2
    }

    .excel-grid {
        @apply shadow-lg rounded-md
    }

    .excel-header-cell {
        @apply flex justify-between items-center bg-success border-dashed select-none
    }

    .excel-fixed-cell {
        @apply flex justify-between items-center bg-success border-dashed select-none
    }

    .excel-cell {
        @apply flex justify-between items-center border-dashed
    }

    .excel-td-fixed {
        @apply border-dashed cursor-default select-none bg-disabled font-normal
    }

    .excel-td {
        @apply border-dashed border-disabled
    }

    .excel-first-row {
        border-bottom-width: 0px;
    }

    .excel-other-row {
        border-top-width: 1px;
    }

    .excel-first-col {
        border-left-width: 0px;
    }

    .excel-other-col {
        border-left-width: 1px;
    }

    .excel-last-col {
        border-right-width: 1px;
    }

    .table-wrapper {
        /* display: block; */
        overflow-x: auto;
        /* white-space: nowrap;         */
    }

    table.doctable {
        table-layout: fixed;
        /* width: 100%; */
        /* overflow-x: auto; */
    }

    /* table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    table thead {
        display: table;
        width: 100%;
    }
    table tbody {
        display: table;
        width: 100%;
    } */
    th {
        /* border: 0; */
        /* width: auto; */
        height: auto;
        min-height: 30px;
        min-width: 40px;
        /* resize: both; */
        resize: horizontal;
        overflow: auto;
    }

    th:first-child {
        resize: none;
    }

    td:first-child {
        /* border: 0; */
        width: auto;
        height: auto;
        min-height: 30px;
        min-width: 40px;
        /* resize: both; */
        resize: vertical;
        overflow: auto;
    }

    .border-style>option:after {
        content: " ";
        height: 5px;
        width: 5px;
        border-radius: 5px;
        display: inline-block;
    }

    option.border-style-red:after {
        background: #c00;
    }

    option.border-style-green:after {
        background: #0c0;
    }

    option.border-style-blue:after {
        background: #00c;
    }
}

.hint>a {
    text-decoration-line: underline;
}

.content {
    /* margin:10px; */
    /* max-width: 100px; */
    width: min-content;
    display: inline-block;
    border: 1px dotted black;
}

.content span {
    flex: 0 1;
    border: 1px dotted black;
}

img.star {
    fill: white;
}
.star path {
    fill: white;
}