body {
    font-family: Montserrat, 'Segoe UI', Arial;
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Segoe UI', Arial, 'Montserrat'; */
    /* font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

.sect {
    @apply px-[40px] overflow-hidden relative;
}

/* gap-[48px] gap-[24px] gap-[24px]  */
/* mx-[140px] py-[100px] gap-[24px] */
@media (width >=768px) {
    .sect {
        @apply px-[140px] pt-[240px] pb-[200px] flex-nowrap relative;
    }
}

@media (width >=1280px) {
    .sect {
        @apply px-[140px] pt-[240px] pb-[200px] flex-row items-end flex-nowrap relative;
    }
}

.sec4 {
    @apply gap-[48px]
}

@media (width >=768px) {
    .sec4 {
        @apply pt-[100px] mb-[0px] pr-[0px] pb-[0px]
    }
}

@media (width >=1280px) {
    .sec4 {
        @apply pt-[100px] mb-[0px]
    }
}

@media (width >=1470px) {
    .sec4 {
        @apply pt-[100px] mb-[0px] pb-[0px]
    }
}

.sect4Text {
    @apply pt-[78px] pr-[40px] md:flex-1 overflow-hidden pb-[140px]
}

@media (width >=1280px) {
    .sect4Text {
        /* @apply mb-[140px] */
    }
}

.sect4Image {
    display: none;
}

@media (width >=1280px) {
    .sect4Image {
        @apply hideOnSmall flex flex-row justify-start md:flex-1 overflow-auto z-40
    }
}

.sectText {
    @apply flex flex-col relative overflow-hidden;
    /* flex-1 flex flex-col justify-around items-start0 relative */
}

@media (width >=1280px) {
    .sectText {
        @apply flex-auto flex flex-col items-start relative overflow-hidden;
    }
}

.sectImage {
    @apply flex-none relative justify-center flex;
}

.sectImageOut {
    @apply relative w-[300px] md:w-[500px] xxl:w-[745px] aspect-square;
}

.h1 {
    font-weight: bold;
    @apply text-[30px] font-bold leading-[32px]
}

@media (width >=768px) {
    .h1 {
        font-weight: bold;
        @apply text-[55px] font-bold leading-[55px]
    }
}

@media (width >=1440px) {
    .h1 {
        font-weight: bold;
        @apply text-[75px] font-bold leading-[75px]
    }
}

.h2 {
    @apply text-[26px] font-bold leading-[30px]
}

/* text-[55px] font-bold leading-[75px]  */
@media (width >=768px) {
    .h2 {
        @apply text-[46px] font-bold leading-[46px]
    }
}

@media (width >=1440px) {
    .h2 {
        @apply text-[56px] font-bold leading-[56px]
    }
}

.h3 {
    color: #474972;
    @apply font-bold text-[24px]
}

@media (width >=768px) {
    .h3 {
        /* color: #474972; */
        @apply font-bold text-[36px]
    }
}

@media (width >=1440px) {
    .h3 {
        /* color: #474972; */
        @apply font-bold text-[48px]
    }
}

.txt {
    @apply text-[18px] leading-[22px]
}

/* text-[24px] leading-[29px] */
@media (width >=768px) {
    .txt {
        @apply text-[24px] leading-[29px]
    }
}

.num {
    @apply text-[46px] font-bold leading-[46px]
}

@media (width >=768px) {
    .num {
        @apply text-[96px] font-bold leading-[96px]
    }
}

.textblock {
    @apply w-[280px] h-[320px] rounded-[40px] p-[20px];
}

/* @media (width >=500px) {
    .textblock {
        @apply w-[480px] h-[400px] rounded-[40px] p-[33px];
    }
} */

@media (width >=768px) {
    .textblock {
        @apply w-[480px] h-[400px] rounded-[40px] p-[33px];
    }
}

.button {
    @apply rounded-full py-[10px] px-[26px] text-[18px] font-bold text-white
}

@media (width >=768px) {
    .button {
        @apply rounded-full py-[16px] px-[36px] text-[26px] font-bold text-white
    }
}

@media (width >=1440px) {
    .button {
        @apply rounded-full py-[18px] px-[56px] text-[36px] font-bold text-white
    }
}

.borderBottom {
    border-bottom: 2px solid #474972;
    padding-bottom: 6px;
    padding-right: 10px;
}

@media (width >=768px) {
    .borderBottom {
        border-bottom: 5px solid #474972;
        padding-bottom: 16px;
        padding-right: 200px;
    }
}

a.promoted {
    color: #474972;
    font-weight: bold;
    text-decoration: underline;
}

span.promoted {
    color: #474972;
    font-weight: bold;
}

.branchLeft {
    @apply rotate-180;
    width: 110px;
    /* left: -40px; */
    /* top: -90px; */
    left: 0;
    top: 0;
}

@media (width >=768px) {
    .branchLeft {
        width: 310px;
        /* left: -120px; */
        /* top: -240px; */
        left: 0;
        top: 0;
    }
}

.branchRight {
    width: 110px;
    right: 4px;
    bottom: 0;
    /* right: -38px; */
    /* bottom: -40px; */
}

@media (width >=768px) {
    .branchRight {
        width: 310px;
        /* right: -110px; */
        /* bottom: -80px; */
        right: 4px;
        bottom: 0;
    }
}

.hideOnSmall {
    display: none;
}

@media (width >=768px) {
    .hideOnSmall {
        display: flex;
    }
}